import { gql } from "@apollo/client";

export const UserFields = gql`
  fragment UserFields on User {
    id
    image
    name
    orgUnit
  }
`;

export const ViewerFields = gql`
  fragment ViewerFields on User {
    id
    firstName
    orgUnit
  }
`;

export default {
  UserFields,
  ViewerFields,
};
