import LAYOUT from "shared/constants/layout";
import styled from "styled-components";

export const ResponsiveGrid = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ResponsiveGridRow = styled.div`
  display: flex;
  flex: 1;
`;

export const ResponsiveGridColumnItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const MaxHeightResponsiveGrid = styled(ResponsiveGrid)`
  padding: 0 0.75rem;

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) and (orientation: landscape) {
    height: 190vh;
  }

  @media (max-height: ${LAYOUT.PORTRAIT_BREAKPOINT_SIZE}) and (orientation: portrait) {
    height: 190vh;
  }

  @media (min-height: ${LAYOUT.PORTRAIT_BREAKPOINT_SIZE}) and (orientation: portrait) {
    height: 100vh;
  }
`;

export const MaxWidthResponsiveGridRow = styled(ResponsiveGridRow)`
  display: flex;
  width: 100%;

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    flex-direction: column;
  }

  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

export const LeftCol = styled(ResponsiveGridColumnItem)`
  display: flex;
  overflow: hidden;
`;

export const RightCol = styled.div`
  flex: 1;
`;
