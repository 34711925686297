import { ComponentType } from "react";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "shared/components/ErrorBoundary";

export interface IProps {
  children: React.ReactNode;
  fallback: ComponentType<any>;
}

export const CrashReporterBoundary = ({ children, fallback }: IProps) => {
  const history = useHistory();

  return (
    <ErrorBoundary FallbackComponent={fallback} history={history}>
      {children}
    </ErrorBoundary>
  );
};

export default CrashReporterBoundary;
