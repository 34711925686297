import LAUNCH_DARKLY from "integrations/constants/launchDarkly";
import { initialize } from "launchdarkly-js-client-sdk";
import ANONYMOUS_LAUNCH_DARKLY_USER from "shared/constants/anonymousLaunchDarklyUser";
import getEnvironment from "shared/utils/getEnvironment";

const environment = getEnvironment();
const launchDarklyID = LAUNCH_DARKLY[environment];

export const client = initialize(launchDarklyID, ANONYMOUS_LAUNCH_DARKLY_USER);

export default client;
