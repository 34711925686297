import { Link } from "react-router-dom";
import ANALYTICS from "shared/constants/analytics";
import COLORS from "shared/constants/colors";
import LAYOUT from "shared/constants/layout";
import { LandmarksProps } from "shared/hooks/useLandmarks/useLandmarks";
import ChevronIcon from "shared/images/icons/chevron.svg";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import textualize from "shared/utils/textualize";
import styled from "styled-components";
import Icon from "../Icon";

const Tile = styled.div`
  background-color: ${COLORS.MID_GREY};
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  padding: 1rem 1rem 1.625rem;
`;

const TileLink = styled(Link)`
  align-items: center;
  color: ${COLORS.WHITE};
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  text-decoration: none;

  &:focus,
  &:hover {
    color: ${COLORS.RED};
  }
`;

const TileHeading = styled.h2`
  margin-bottom: 1rem;
`;

const TileListItem = styled.li`
  margin-bottom: 1.625rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    && {
      display: block;
    }
  }
`;

const StyledIcon = styled(Icon)`
  color: ${COLORS.LIGHTER_GREY};
  transform: rotate(90deg);

  svg {
    height: 0.625rem;
  }
`;

const RecentlyVisitedTile = ({ items }: { items: Array<LandmarksProps> }) => {
  const links = items.slice(0, 5).map((item: LandmarksProps, i) => (
    <TileListItem key={i}>
      <TileLink
        onClick={() =>
          fireAnalyticsEvent(
            ANALYTICS.CATEGORIES.DASHBOARD,
            ANALYTICS.EVENTS.RECENTLY_VIEWED_CLICKED,
          )
        }
        to={item.url}
      >
        {item.title}
        <StyledIcon component={ChevronIcon} />
      </TileLink>
    </TileListItem>
  ));

  return (
    <Tile>
      <TileHeading>{textualize("general.recentlyVisited.heading")}</TileHeading>
      <ul>{links}</ul>
    </Tile>
  );
};

export default RecentlyVisitedTile;
