import { useMemo } from "react";
import { Link } from "react-router-dom";
import { buttonStyles, minorButtonStyles } from "shared/components/Button";
import ANALYTICS from "shared/constants/analytics";
import COLORS from "shared/constants/colors";
import LAYOUT from "shared/constants/layout";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import useReduceMotion from "shared/hooks/useReduceMotion";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import textualize from "shared/utils/textualize";
import styled from "styled-components";

const Tile = styled.div`
  align-items: center;
  background-color: ${COLORS.MID_GREY};
  border-radius: 0.5rem;
  display: flex;
  margin-bottom: 1.875rem;
  padding: 1rem 1rem 1.625rem;

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    display: block;
    white-space: normal;
  }
`;

const TileButton = styled(Link)`
  ${buttonStyles}
  ${minorButtonStyles}
  display: inline-block;
`;

const TileContent = styled.p`
  margin-bottom: 1.5rem;
`;

const TileHeading = styled.h2`
  margin-bottom: 0.75rem;
`;

const TileLeftCol = styled.div``;

const TileRightCol = styled.div`
  padding-left: 1rem;
  max-width: 50%;

  @media (max-width: ${LAYOUT.PORTRAIT_BREAKPOINT_SIZE}) {
    max-width: 43%;
  }

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    display: none;
  }
`;

const SlackTile = () => {
  const prefersReducedMotion = useReduceMotion();

  const memoedAnimation = useMemo(
    () => ({
      filename: "internal/dashboard/v1-slack-tile.json",
      height: "auto",
    }),
    [],
  );
  const { AnimationComponent } = useLottieAnimation({
    animation: memoedAnimation,
    disabled: prefersReducedMotion,
    loop: false,
  });

  return (
    <Tile>
      <TileLeftCol>
        <TileHeading>{textualize("general.slackTile.heading")}</TileHeading>
        <TileContent>{textualize("general.slackTile.content")}</TileContent>
        <TileButton
          aria-label={textualize("general.slackTile.button") as string}
          onClick={() => {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.DASHBOARD,
              ANALYTICS.EVENTS.SLACK_TILE_CLICKED,
            );
          }}
          rel="noopener noreferrer"
          target="_blank"
          to={{
            pathname:
              "https://vidsyhq.slack.com/app_redirect?channel=C03E8U6J0AX",
          }}
        >
          {textualize("general.slackTile.button")}
        </TileButton>
      </TileLeftCol>
      <TileRightCol>
        <div
          aria-label={textualize("general.slackTile.heading") as string}
          role="img"
        >
          {AnimationComponent}
        </div>
      </TileRightCol>
    </Tile>
  );
};

export default SlackTile;
