import GlobalNav from "integrations/components/GlobalNav";
import Session from "integrations/services/Session";
import { Route, RouteProps } from "react-router-dom";
import FetchViewer from "shared/components/FetchViewer";
import SessionDetails from "shared/components/SessionDetails";
import Application from "shared/types/application";
import getOrigin from "shared/utils/getOrigin";

interface IGuardedRouteProps extends RouteProps {
  children?: React.ReactNode;
  fullscreen?: boolean;
  isAuthenticated: () => boolean;
}

function GuardedRoute({
  children,
  exact,
  fullscreen,
  isAuthenticated,
  path,
}: IGuardedRouteProps) {
  if (!isAuthenticated()) {
    window.location.href = `${getOrigin(Application.Internal)}/login`;
  }

  return (
    <Route exact={exact} path={path}>
      {!fullscreen && <GlobalNav />}
      <SessionDetails Session={Session} />

      <FetchViewer>{children}</FetchViewer>
    </Route>
  );
}

export default GuardedRoute;
