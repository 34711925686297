import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import useAnalytics from "shared/hooks/useAnalytics";
import { GET_VIEWER } from "shared/queries/viewer";
import { ViewerQuery as IGetViewer } from "shared/types/graphql";

export interface IProps {
  isAuthenticated: () => boolean;
  segmentID: string;
}

export const Analytics = ({ isAuthenticated, segmentID }: IProps) => {
  const { data } = useQuery<IGetViewer>(GET_VIEWER);

  const user = useMemo(() => {
    if (!data?.viewer) {
      return undefined;
    }

    return {
      disableAnalytics: data.viewer.disableAnalytics,
      id: data.viewer.id,
      name: data.viewer.name,
    };
  }, [data?.viewer]);

  useAnalytics(segmentID, isAuthenticated, user);

  return null;
};

export default Analytics;
