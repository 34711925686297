import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import LoadingIcon from "shared/images/icons/loading-spinner.svg";
import styled from "styled-components";

export interface IProps {
  className?: string;
}

const LoadingSpinner = styled(Icon)`
  @keyframes loading {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }

  animation: loading 0.6s linear infinite;
  bottom: 0;
  color: ${COLORS.RED};
  height: 4rem;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 4rem;

  > svg {
    display: block;
  }
`;

const LoadingElement = ({ className }: IProps) => (
  <LoadingSpinner className={className} component={LoadingIcon} />
);
export default LoadingElement;
