import { MOBILE_PREVIEW } from "./mobilePreview";

export default {
  BRIEF: {
    BREADCRUMB_HEADING: "sharedBriefBreadcrumbHeading",
  },
  CHANGE_PIN: {
    CANCEL: "showreelChangePinModalCancelButton",
    CONFIRM: "showreelChangePinModalConfirmButton",
    FIELDSET: "showreelChangePinModalFieldset",
    INPUT: "showreelChangePinModalInput",
    MODAL: "showreelChangePinModal",
    MODAL_CLOSE: "showreelChangePinModalClose",
    MODAL_OPEN: "showreelChangePinModalOpen",
  },
  ENTER_PIN: {
    CANCEL: "showreelEnterPinModalCancelButton",
    CONFIRM: "showreelEnterPinModalConfirmButton",
    FIELDSET: "showreelEnterPinModalFieldset",
    INPUT: "showreelEnterPinModalInput",
    MODAL: "showreelEnterPinModal",
  },
  ERROR: {
    DATA: {
      RELOAD: "sharedErrorDataReload",
    },
  },
  GLOBAL_NAV: {
    BRANDS: "sharedGlobalNavBrands",
    CREATORS: "sharedGlobalNavCreators",
    DASHBOARD: "sharedGlobalNavDashboard",
    FEATURES: "sharedGlobalNavFeatures",
    HEADER: "sharedGlobalNavHeader",
    INSPO: "sharedGlobalNavInspo",
    MEGA_SEARCH: "sharedGlobalNavMegaSearch",
    PAYMENTS: "sharedGlobalNavPayments",
    SIGN_OUT: "sharedGlobalNavSignOut",
    USER: "sharedGlobalNavUser",
    VIDSY_LOGO: "sharedGlobalNavVidsyLogo",
  },
  MOBILE_PREVIEW,
  MODALS: {
    INSERT_MARKDOWN: {
      CANCEL: "sharedModalsInsertMarkdownCancel",
      ID: "sharedModalsInsertMarkdownModal",
      INSERT: "sharedModalsInsertMarkdownInsert",
      LINK_DISPLAY_TEXT_INPUT: "sharedModalsInsertMarkdownDisplayTextInputLink",
      SELECT_ALL_CHECKBOX: "sharedModalsInsertMarkdownSelectAllCheckbox",
      URL_INPUT: "sharedModalsInsertMarkdownUrlInput",
      VIDEO_ASPECT_RATIO: "sharedModalsInsertMarkdownVideoAspectRatio",
    },
    SESSION_EXPIRED: {
      ID: "sharedModalsSessionExpired",
      LOGIN_LINK: "sharedModalsSessionExpiredLoginLink",
      REAUTH_BUTTON: "sharedModalsSessionExpiredReauthButton",
    },
    SHARE_MODAL: {
      CANCEL: "sharedModalsShareModalCancel",
      COPY: "sharedModalsShareModalCopy",
      ID: "sharedModalsShareModal",
      INPUT: "sharedModalsShareModalInput",
      LINK: "sharedModalsShareModalLink",
      TIME_CHECKBOX: "sharedModalsShareModalTimeCheckbox",
    },
  },
  PHONE_CAROUSEL: {
    CONTAINER: "sharedPhoneCarouselContainer",
    NEXT: "sharedPhoneCarouselNext",
    NEXT_ITEM: "sharedPhoneCarouselNextItem",
    PREVIOUS: "sharedPhoneCarouselPrevious",
    PREVIOUS_ITEM: "sharedPhoneCarouselPreviousItem",
    STAGE: "sharedPhoneCarouselStage",
    VIDEO: "sharedPhoneCarouselVideo",
  },
  SESSION_DETAILS: {
    BANNER: "sharedSessionDetailsBanner",
  },
  TOAST: {
    CONTAINER: "sharedToastContainer",
  },
  VIDEO_CONTROLS: {
    APPEARANCE_SELECTOR: "sharedVideoControlsAppearanceSelector",
    CONTAINER: "sharedVideoControlsContainer",
    CURRENT_TIME: "sharedVideoControlsCurrentTime",
    DURATION: "sharedVideoControlsDuration",
    HDR_TOGGLE: "sharedVideoControlsHDRToggle",
    HDR_ICON_ENABLED: "sharedVideoControlsHDRIconEnabled",
    HDR_ICON_DISABLED: "sharedVideoControlsHDRIconDisabled",
    MUTE_TOGGLE: "sharedVideoControlsMuteToggle",
    PLAY_TOGGLE: "sharedVideoControlsPlayToggle",
    SCRUBBER_KNOB: "sharedVideoControlsScrubberKnob",
    SCRUBBER: "sharedVideoControlsScrubber",
    SHARED_VIDEO: "sharedVideoControlsShareButton",
    TIMESTAMP: {
      BUTTON: "sharedVideoControlsTimestampButton",
      CONTAINER: "sharedVideoControlsTimestampContainer",
    },
    TRACK: "sharedVideoControlsTrack",
  },
};
