import { useContext } from "react";
import ReactDom from "react-dom";
import { animated, useTransition } from "react-spring";
import Icon from "shared/components/Icon";
import Toast from "shared/components/Toast";
import { ToastContext } from "shared/components/ToastProvider";
import IDS from "shared/constants/ids";
import CheckIcon from "shared/images/icons/check.svg";
import WarningIcon from "shared/images/icons/warning.svg";
import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: fixed;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 100;

  > ${Toast} {
    pointer-events: auto;
  }
`;

const StyledIcon = styled(Icon)`
  vertical-align: text-bottom;
`;

export const AnimatedToast = animated(Toast);

export const ToastContainer = () => {
  const { removeToast = () => null, toasts } = useContext(ToastContext);

  const transitions = useTransition(toasts, {
    enter: {
      height: "auto",
      marginBottom: "1rem",
      opacity: 1,
      padding: "0.75rem",
      transform: "translateY(0)",
    },
    from: {
      height: 0,
      marginBottom: "0rem",
      opacity: 0,
      padding: "0.75rem",
      transform: "translateY(-100%)",
    },
    leave: [
      { opacity: 0, transform: "translateY(25%)" },
      { height: 0, marginBottom: "0rem", padding: "0rem" },
    ],
  });

  const node = (
    <StyledContainer id={IDS.TOAST.CONTAINER}>
      {transitions((props, item, { key }) => (
        <AnimatedToast
          className={item.isWarning ? "isWarning" : ""}
          key={key}
          onClick={() => removeToast(item.id)}
          style={{ ...props }}
        >
          <StyledIcon component={item.isWarning ? WarningIcon : CheckIcon} />{" "}
          {item.message}
        </AnimatedToast>
      ))}
    </StyledContainer>
  );

  return ReactDom.createPortal(
    node,
    document.getElementById("toast-root") as Element,
  );
};

export default ToastContainer;
