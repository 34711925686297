import { Helmet } from "react-helmet";
import PageContainer from "shared/components/PageContainer";
import PageHeading from "shared/components/PageHeading";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import textualize from "shared/utils/textualize";
import { Column, FullHeight } from "../ErrorComponents";
import unrecoverableErrorAnimation from "./unrecoverableErrorAnimation";

const UnrecoverableError = () => {
  const { AnimationComponent: UnrecoverableErrorAnimation } =
    useLottieAnimation({
      animation: unrecoverableErrorAnimation,
    });

  return (
    <>
      <Helmet title={textualize("error.unrecoverable.heading") as string} />
      <PageContainer>
        <FullHeight>
          <Column>
            <PageHeading>
              {textualize("error.unrecoverable.heading")}
            </PageHeading>
            <p>{textualize("error.unrecoverable.body")}</p>
          </Column>
          <Column>{UnrecoverableErrorAnimation}</Column>
        </FullHeight>
      </PageContainer>
    </>
  );
};

export default UnrecoverableError;
