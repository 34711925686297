import { useQuery } from "@apollo/client";
import { GET_VIEWER } from "shared/queries/viewer";

export const FetchViewer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { loading, error } = useQuery(GET_VIEWER);

  if (error) {
    // Handled by error boundary
    throw error;
  }

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

export default FetchViewer;
