import useLottieAnimation from "shared/hooks/useLottieAnimation";
import textualize from "shared/utils/textualize";
import styled from "styled-components";
import { Column, FullHeight } from "../ErrorComponents";
import squeezedAnimation from "./squeezedAnimation";

const ChildrenContainer = styled.div`
  @media (max-width: 719px) {
    > * {
      display: none;
    }
  }
`;

const StyledDiv = styled.div`
  p {
    width: 100%;
    text-align: center;
  }

  ${Column} div {
    margin: 0 auto;

    &[role="button"] {
      margin-bottom: -25% !important;
    }
  }

  @media (min-width: 720px) {
    > * {
      display: none;
    }
  }
`;

export interface IProps {
  children: React.ReactNode;
}

const SqueezedError = ({ children }: IProps) => {
  const { AnimationComponent: SqueezedErrorAnimation } = useLottieAnimation({
    animation: squeezedAnimation,
  });

  return (
    <>
      <StyledDiv>
        <FullHeight>
          <Column>
            {SqueezedErrorAnimation}
            <p>{textualize("error.squeezed.body")}</p>
          </Column>
        </FullHeight>
      </StyledDiv>
      <ChildrenContainer>{children}</ChildrenContainer>
    </>
  );
};

export default SqueezedError;
