import { useQuery } from "@apollo/client";
import { LDClient, LDSingleKindContext } from "launchdarkly-js-client-sdk";
import { useEffect, useMemo } from "react";
import { GET_VIEWER } from "shared/queries/viewer";
import { ViewerQuery as IGetViewer } from "shared/types/graphql";

export interface IProps {
  client: LDClient;
}

export const LDIdentifier = ({ client }: IProps) => {
  const { data } = useQuery<IGetViewer>(GET_VIEWER);

  const user = useMemo(() => {
    if (!data?.viewer) {
      return undefined;
    }

    const context: LDSingleKindContext = {
      appVersion: process.env.VERSION!,
      avatar: data.viewer.image || undefined,
      key: data.viewer.id,
      kind: "user",
      name: data.viewer.name,
      orgUnit: data.viewer.orgUnit,
    };

    return context;
  }, [data?.viewer]);

  useEffect(() => {
    if (client && user) {
      client.identify(user);
    }
  }, [client, user]);

  return null;
};

export default LDIdentifier;
