import { useQuery } from "@apollo/client";
import Session from "integrations/services/Session";
import { useCallback, useRef } from "react";
import Avatar from "shared/components/Avatar";
import {
  AvatarContainer,
  BrandItems,
  Dashboard,
  NavAnchor,
  StyledHeader,
  StyledVidsyLogo,
  UserItems,
  UserPopover,
} from "shared/components/GlobalNav/styles";
import { default as NavItems } from "shared/components/GlobalNavItems";
import { PopoverButton } from "shared/components/Popover/PopoverComponents";
import SHARED_ANALYTICS from "shared/constants/analytics";
import IDS from "shared/constants/ids";
import HomeIcon from "shared/images/icons/home.svg";
import { GET_VIEWER } from "shared/queries/viewer";
import Auth from "shared/services/InternalAuth";
import Application from "shared/types/application";
import { ViewerQuery as IViewer } from "shared/types/graphql";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import getOrigin from "shared/utils/getOrigin";
import textualize from "shared/utils/textualize";

export const GlobalNav = () => {
  const { data, loading, error } = useQuery<IViewer>(GET_VIEWER);

  const signOut = useCallback(() => {
    Auth.logout(`${getOrigin(Application.Internal)}/login`);
    Session.clear();
  }, []);

  const signOutRef = useRef(null);

  if (loading || error || !data) {
    return null;
  }

  const { viewer: user } = data;

  return (
    <StyledHeader id={IDS.GLOBAL_NAV.HEADER}>
      <NavItems>
        <li>
          <NavAnchor
            aria-label={textualize("nav.dashboard") as string}
            href={`${getOrigin(Application.Internal)}/`}
            id={IDS.GLOBAL_NAV.DASHBOARD}
            onClick={() =>
              fireAnalyticsEvent(
                SHARED_ANALYTICS.CATEGORIES.DASHBOARD,
                SHARED_ANALYTICS.EVENTS.NAVIGATED_TO_DASHBOARD,
              )
            }
          >
            <Dashboard component={HomeIcon} />
          </NavAnchor>
        </li>
        <li>
          <NavAnchor
            href={`${getOrigin(Application.Internal)}/brands`}
            id={IDS.GLOBAL_NAV.BRANDS}
          >
            {textualize("nav.brands")}
          </NavAnchor>
        </li>
        <li>
          <NavAnchor
            href={`${getOrigin(Application.Internal)}/creators`}
            id={IDS.GLOBAL_NAV.CREATORS}
          >
            {textualize("nav.creators")}
          </NavAnchor>
        </li>
        <li>
          <NavAnchor
            href={`${getOrigin(Application.Internal)}/payments/awaiting`}
            id={IDS.GLOBAL_NAV.PAYMENTS}
          >
            {textualize("nav.payments")}
          </NavAnchor>
        </li>
      </NavItems>
      <BrandItems>
        <a
          aria-label={`${textualize("nav.dashboard")}`}
          href={`${getOrigin(Application.Internal)}/`}
          id={IDS.GLOBAL_NAV.VIDSY_LOGO}
        >
          <StyledVidsyLogo />
        </a>
      </BrandItems>
      <UserItems>
        {!loading && data && (
          <UserPopover
            id={IDS.GLOBAL_NAV.USER}
            label={textualize("nav.userItems") as string}
            trigger={
              <AvatarContainer>
                <Avatar>
                  {user.image && <img alt={user.name} src={user.image} />}
                </Avatar>
              </AvatarContainer>
            }
          >
            {/* Feature flags will not work if set within internal but linked from integrations */}
            {/* TODO: Feature flag support for integrations */}
            <PopoverButton
              id={IDS.GLOBAL_NAV.SIGN_OUT}
              onClick={signOut}
              ref={signOutRef}
            >
              {textualize("nav.signOut")}
            </PopoverButton>
          </UserPopover>
        )}
      </UserItems>
    </StyledHeader>
  );
};

export default GlobalNav;
