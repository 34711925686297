import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Session from "integrations/services/Session";
import API from "shared/constants/api";
import { traceLink } from "shared/services/GraphQL/link";
import getEnvironment from "shared/utils/getEnvironment";

const uri = API.INTEGRATIONS[getEnvironment()];

export const createAuthContext = (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const session = Session.getSession();
  const authorization = session
    ? `${session.tokenType} ${session.idToken}`
    : "";
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization,
      "client-name": "Vidsy [web]",
      "client-version": "0.0.1",
    },
  };
};

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext(createAuthContext);

const link = authLink.concat(traceLink).concat(httpLink);

export default link;
