export const MOBILE_PREVIEW = {
  FACEBOOK: {
    CAROUSEL: {
      COMMENT_LABEL: "sharedMobilePreviewFacebookCarouselCommentLabel",
      COMMENT_SHARE_ICONS:
        "sharedMobilePreviewFacebookCarouselCommentShareIcons",
      CTA: {
        BUTTON: "sharedMobilePreviewFacebookCarouselCTAButton",
        CAPTION: "sharedMobilePreviewFacebookCarouselCTACaption",
      },
      LIKE: "sharedMobilePreviewFacebookCarouselLike",
      SHARE_LABEL: "sharedMobilePreviewFacebookCarouselShareLabel",
    },
    INFEED: {
      BRAND_CAPTION: "sharedMobilePreviewFacebookInfeedBrandCaption",
      BRAND_LOGO: "sharedMobilePreviewFacebookInfeedBrandLogo",
      BRAND_NAME: "sharedMobilePreviewFacebookInfeedBrandName",
      CAMERA: "sharedMobilePreviewFacebookInfeedCamera",
      CARD_DIVIDER: "sharedMobilePreviewFacebookInfeedCardDivider",
      COMMENT_LABEL: "sharedMobilePreviewFacebookInfeedCommentLabel",
      COMMENT_SHARE_ICONS: "sharedMobilePreviewFacebookInfeedCommentShareIcons",
      COMMENTS_SHARES: "sharedMobilePreviewFacebookInfeedCommentsShares",
      CTA: {
        BUTTON: "sharedMobilePreviewFacebookInfeedCTAButton",
        CAPTION: "sharedMobilePreviewFacebookInfeedCTACaption",
        HEADER: "sharedMobilePreviewFacebookInfeedCTAHeader",
      },
      FACEBOOK_LOGO: "sharedMobilePreviewFacebookInfeedFacebookLogo",

      GLOBE_ICON: "sharedMobilePreviewFacebookInfeedGlobeIcon",
      LIKE: "sharedMobilePreviewFacebookInfeedLike",
      LIKES: "sharedMobilePreviewFacebookInfeedLikes",
      LOVES: "sharedMobilePreviewFacebookInfeedLoves",

      MESSENGER: "sharedMobilePreviewFacebookInfeedMessenger",

      NEXT_CARD: {
        BRAND_DESCRIPTION:
          "sharedMobilePreviewFacebookInfeedNextCardBrandDescription",
        BRAND_LOGO: "sharedMobilePreviewFacebookInfeedNextCardBrandLogo",
        VIDEO: "sharedMobilePreviewFacebookInfeedNextCardVideo",
      },

      REACTION_COUNT: "sharedMobilePreviewFacebookInfeedReactionCount",
      SEARCH_ICON: "sharedMobilePreviewFacebookInfeedSearchIcon",
      SHARE_LABEL: "sharedMobilePreviewFacebookInfeedShareLabel",
      SPONSORED: "sharedMobilePreviewFacebookInfeedSponsored",
    },
    SHARED: {
      TAB: {
        ICON: {
          GROUPS: "sharedMobilePreviewFacebookSharedTabGroupsIcon",
          MARKETPLACE: "sharedMobilePreviewFacebookSharedTabMarketplaceIcon",
          MENU: "sharedMobilePreviewFacebookSharedTabMenuIcon",
          NEWS_FEED: "sharedMobilePreviewFacebookSharedTabNewsFeedIcon",
          NOTIFICATIONS:
            "sharedMobilePreviewFacebookSharedTabNotificationsIcon",
          WATCH: "sharedMobilePreviewFacebookSharedTabWatchIcon",
        },
        TEXT: {
          GROUPS: "sharedMobilePreviewFacebookSharedTabGroupsText",
          MARKETPLACE: "sharedMobilePreviewFacebookSharedTabMarketplaceText",
          MENU: "sharedMobilePreviewFacebookSharedTabMenuText",
          NEWS_FEED: "sharedMobilePreviewFacebookSharedTabNewsFeedText",
          NOTIFICATIONS:
            "sharedMobilePreviewFacebookSharedTabNotificationsText",
          WATCH: "sharedMobilePreviewFacebookSharedTabWatchText",
        },
      },
    },
    STORIES: {
      BOTTOM_VIGNETTE: "sharedMobilePreviewFacebookStoriesBottomVignette",
      BRAND_LOGO: "sharedMobilePreviewFacebookStoriesBrandLogo",
      BRAND_NAME: "sharedMobilePreviewFacebookStoriesBrandName",
      CLOSE: "sharedMobilePreviewFacebookStoriesClose",
      CTA: {
        LEARN_MORE: "sharedMobilePreviewFacebookStoriesCTALearnMore",
      },
      MORE: "sharedMobilePreviewFacebookStoriesMore",
      SPONSORED: "sharedMobilePreviewFacebookStoriesSponsored",
      TIMEBARS: "sharedMobilePreviewFacebookStoriesTimeBars",
    },
  },
  INSTAGRAM: {
    CAROUSEL: {
      BOOKMARK: "sharedMobilePreviewInstagramCarouselBookmark",
      LIKE_COMMENT: "sharedMobilePreviewInstagramCarouselLikeComment",
      PIPS: "sharedMobilePreviewInstagramCarouselPips",
      SHARE: "sharedMobilePreviewInstagramCarouselShare",
      VIEWS: "sharedMobilePreviewInstagramCarouselViews",
    },
    INFEED: {
      ACTIVITY_TAB: "sharedMobilePreviewInstagramInfeedActivityTab",
      BOOKMARK: "sharedMobilePreviewInstagramInfeedBookmark",
      BRAND_LOGO: "sharedMobilePreviewInstagramInfeedBrandLogo",
      BRAND_NAME: "sharedMobilePreviewInstagramInfeedBrandName",
      CAMERA: "sharedMobilePreviewInstagramInfeedCamera",
      CAPTION: "sharedMobilePreviewInstagramInfeedCaption",
      COMMENTS: "sharedMobilePreviewInstagramInfeedComments",
      CTA: {
        ARROW: "sharedMobilePreviewInstagramInfeedCTAArrow",
        BUTTON: "sharedMobilePreviewInstagramInfeedCTAButton",
      },
      EXPLORE_TAB: "sharedMobilePreviewInstagramInfeedExploreTab",
      HOME_TAB: "sharedMobilePreviewInstagramInfeedHomeTab",
      LIKE_COMMENT: "sharedMobilePreviewInstagramInfeedLikeComment",
      LOGO: "sharedMobilePreviewInstagramInfeedLogo",
      MESSAGES: "sharedMobilePreviewInstagramInfeedMessages",
      NEXT_CARD: {
        BRAND_LOGO_PLACEHOLDER:
          "sharedMobilePreviewInstagramInfeedNextCardBrandLogoPlaceholder",
        BRAND_NAME: "sharedMobilePreviewInstagramInfeedNextCardBrandName",
        MORE: "sharedMobilePreviewInstagramInfeedNextCardMore",
        VIDEO_AD: "sharedMobilePreviewInstagramInfeedNextCardVideoAd",
      },
      PROFILE_TAB: "sharedMobilePreviewInstagramInfeedProfileTab",
      SHARE: "sharedMobilePreviewInstagramInfeedShare",
      SPONSORED: "sharedMobilePreviewInstagramInfeedSponsored",
      UPLOAD_TAB: "sharedMobilePreviewInstagramInfeedUploadTab",
      VIEWS: "sharedMobilePreviewInstagramInfeedViews",
    },
    REELS: {
      BRAND_LOGO: "sharedMobilePreviewInstagramReelsBrandLogo",
      BRAND_NAME: "sharedMobilePreviewInstagramReelsBrandName",
      CAMERA: "sharedMobilePreviewInstagramReelsCamera",
      COMMENTS: "sharedMobilePreviewInstagramReelsComments",
      CTA_CAPTION: "sharedMobilePreviewInstagramReelsCtaCaption",
      EXPLORE_TAB: "sharedMobilePreviewInstagramReelsExploreTab",
      HEADER: "sharedMobilePreviewInstagramReelsHeader",
      HOME_TAB: "sharedMobilePreviewInstagramReelsHomeTab",
      LIKES: "sharedMobilePreviewInstagramReelsLikes",
      PROFILE_TAB: "sharedMobilePreviewInstagramReelsProfileTab",
      REELS_TAB: "sharedMobilePreviewInstagramReelsReelsTab",
      SHOP_TAB: "sharedMobilePreviewInstagramReelsShopTab",
      SPONSORED: "sharedMobilePreviewInstagramReelsSponsored",
    },
    STORIES: {
      BOTTOM_VIGNETTE: "sharedMobilePreviewInstagramStoriesBottomVignette",
      BRAND_LOGO: "sharedMobilePreviewInstagramStoriesBrandLogo",
      BRAND_NAME: "sharedMobilePreviewInstagramStoriesBrandName",
      CLOSE: "sharedMobilePreviewInstagramStoriesClose",
      CTA: {
        MORE: "sharedMobilePreviewInstagramStoriesCTAMore",
      },
      SPONSORED: "sharedMobilePreviewInstagramStoriesSponsored",
      TIMEBARS: "sharedMobilePreviewInstagramStoriesTimeBars",
      TOP_VIGNETTE: "sharedMobilePreviewInstagramStoriesTopVignette",
    },
  },
  LINKED_IN: {
    INFEED: {
      BRAND_CAPTION: "sharedMobilePreviewLinkedInInfeedBrandCaption",
      BRAND_LOGO: "sharedMobilePreviewLinkedInInfeedBrandLogo",
      BRAND_NAME: "sharedMobilePreviewLinkedInInfeedBrandName",
      CTA: {
        BUTTON: "sharedMobilePreviewLinkedInInfeedCTAButton",
        CAPTION: "sharedMobilePreviewLinkedInInfeedCTACaption",
      },
    },
  },
  PINTEREST: {
    MAX_WIDTH: {
      BRAND_LOGO: "sharedMobilePreviewPinterestMaxWidthBrandLogo",
      BRAND_NAME: "sharedMobilePreviewPinterestMaxWidthBrandName",
      CAMERA: "sharedMobilePreviewPinterestMaxWidthCamera",
      CTA_CAPTION: "sharedMobilePreviewPinterestMaxWidthCTACaption",
      MORE: "sharedMobilePreviewPinterestMaxWidthMore",
      PIN: "sharedMobilePreviewPinterestMaxWidthPin",
      PROMOTED_BY: "sharedMobilePreviewPinterestMaxWidthPromotedBy",
    },
    SHARED: {
      TAB: {
        HOME: "sharedMobilePreviewPinterestStandardWidthTabHome",
        NOTIFICATIONS:
          "sharedMobilePreviewPinterestStandardWidthTabNotifications",
        SAVED: "sharedMobilePreviewPinterestStandardWidthTabSaved",
        SEARCH: "sharedMobilePreviewPinterestStandardWidthTabSearch",
      },
    },
    STANDARD_WIDTH: {
      BRAND_LOGO: "sharedMobilePreviewPinterestStandardWidthBrandLogo",
      BRAND_NAME: "sharedMobilePreviewPinterestStandardWidthBrandName",
      CAMERA: "sharedMobilePreviewPinterestStandardWidthCamera",
      CAPTION: "sharedMobilePreviewPinterestStandardWidthCaption",
      CTA_CAPTION: "sharedMobilePreviewPinterestStandardWidthCTACaption",
      MESSAGES: "sharedMobilePreviewPinterestStandardWidthMessages",
      MORE: "sharedMobilePreviewPinterestStandardWidthMore",
      PIN: "sharedMobilePreviewPinterestStandardWidthPin",
      PROMOTED_BY: "sharedMobilePreviewPinterestStandardWidthPromotedBy",
      SEARCH_BAR: "sharedMobilePreviewPinterestStandardWidthSearchBar",
    },
  },
  REDDIT: {
    PROMOTED_POST: {
      BRAND_LOGO: "sharedMobilePreviewRedditPromotedPostBrandLogo",
      BRAND_NAME: "sharedMobilePreviewRedditPromotedPostBrandName",
    },
  },
  SNAPCHAT: {
    AD: {
      ARROW_ICON: "sharedMobilePreviewSnapchatAdArrowIcon",
      BRAND_CAPTION: "sharedMobilePreviewSnapchatAdBrandCaption",
      BRAND_DESCRIPTION: "sharedMobilePreviewSnapchatAdBrandDescription",
      BRAND_LOGO: "sharedMobilePreviewSnapchatAdBrandLogo",
      BRAND_NAME: "sharedMobilePreviewSnapchatAdBrandName",
      CTA: {
        AD: "sharedMobilePreviewSnapchatAdCTAAd",
        HEADER: "sharedMobilePreviewSnapchatAdCTAHeader",
        SWIPE_ICON: "sharedMobilePreviewSnapchatAdCTASwipeIcon",
      },
      DIVIDER: "sharedMobilePreviewSnapchatAdDivider",
      MORE: "sharedMobilePreviewSnapchatAdMore",
    },
    APP_AD: {
      CTA: {
        AD: "sharedMobilePreviewSnapchatAppAdCTAAd",
        APP_DESCRIPTION: "sharedMobilePreviewSnapchatAppAdCTAApDescription",
        APP_NAME: "sharedMobilePreviewSnapchatAppAdCTAApName",
        APP_PICTURE: "sharedMobilePreviewSnapchatAppAdCTAApPicture",
        BUTTON: "sharedMobilePreviewSnapchatAppAdCTAButton",
        CONTAINER: "sharedMobilePreviewSnapchatAppAdCTAContainer",
        HEADER: "sharedMobilePreviewSnapchatAppAdCTAHeader",
        SWIPE_ICON: "sharedMobilePreviewSnapchatAppAdCTASwipeIcon",
      },
    },
    COLLECTION: {
      CTA: {
        AD: "sharedMobilePreviewSnapchatCollectionCTAAd",
        HEADER: "sharedMobilePreviewSnapchatCollectionCTAHeader",
        SWIPE_ICON: "sharedMobilePreviewSnapchatCollectionCTASwipeIcon",
      },
      SNAP: "sharedMobilePreviewSnapchatCollectionSnap",
    },
    STORY_AD: {
      CTA: {
        HEADER: "sharedMobilePreviewSnapchatStoryAppAdCTAHeader",
        SWIPE_ICON: "sharedMobilePreviewSnapchatStoryAppAdCTASwipeIcon",
      },
    },
  },
  TIKTOK: {
    INFEED: {
      BRAND_ACCOUNT: "sharedMobilePreviewTikTokInfeedBrandAccount",
      BRAND_CAPTION: "sharedMobilePreviewTikTokInfeedBrandCaption",
      BRAND_HASHTAG: "sharedMobilePreviewTikTokInfeedBrandHashtag",
      BRAND_LOGO: "sharedMobilePreviewTikTokInfeedBrandLogo",
      COMMENTS: "sharedMobilePreviewTikTokInfeedComments",
      CTA: "sharedMobilePreviewTikTokInfeedCTA",
      FAVOURITE: "sharedMobilePreviewTikTokInfeedFavourite",
      FOLLOWING: "sharedMobilePreviewTikTokInfeedFollowing",
      FOR_YOU: "sharedMobilePreviewTikTokInfeedForYou",
      LIKES: "sharedMobilePreviewTikTokInfeedLikes",
      PROFILE: "sharedMobilePreviewTikTokInfeedProfile",
      PROMOTED_MUSIC: "sharedMobilePreviewTikTokInfeedPromotedMusic",
      RECORD: "sharedMobilePreviewTikTokInfeedRecord",
      SHARE_LABEL: "sharedMobilePreviewTikTokInfeedShareLabel",
      SHARES: "sharedMobilePreviewTikTokInfeedShares",
      SPONSORED: "sharedMobilePreviewTikTokInfeedSponsored",
      TAB: {
        ADD: "sharedMobilePreviewTikTokInfeedTabAdd",
        DISCOVER: "sharedMobilePreviewTikTokInfeedTabDiscover",
        HOME: "sharedMobilePreviewTikTokInfeedTabHome",
        INBOX: "sharedMobilePreviewTikTokInfeedTabInbox",
        POST: "sharedMobilePreviewTikTokInfeedTabPost",
        PROFILE: "sharedMobilePreviewTikTokInfeedTabProfile",
      },
    },
  },
  TWITTER: {
    INFEED: {
      BRAND_ACCOUNT: "sharedMobilePreviewTwitterInfeedBrandAccount",
      BRAND_LOGO: "sharedMobilePreviewTwitterInfeedBrandLogo",
      BRAND_NAME: "sharedMobilePreviewTwitterInfeedBrandName",
      COMMENTS_COUNT: "sharedMobilePreviewTwitterInfeedCommentsCount",
      COMMENTS_LIKES_ICONS:
        "sharedMobilePreviewTwitterInfeedCommentsLikesIcons",
      COMPOSE_TWEET: "sharedMobilePreviewTwitterInfeedComposeTweet",
      CTA_CAPTION: "sharedMobilePreviewTwitterInfeedCTACaption",
      DROPDOWN_ARROW: "sharedMobilePreviewTwitterInfeedDropdownArrow",
      LIKES_COUNT: "sharedMobilePreviewTwitterInfeedLikesCount",
      NAVIGATION_BAR: "sharedMobilePreviewTwitterInfeedNavigationBar",
      NEXT_CARD: {
        BRAND_LOGO: "sharedMobilePreviewTwitterInfeedNextCardBrandLogo",
        CONTENT: "sharedMobilePreviewTwitterInfeedNextCardContent",
        DIVIDER: "sharedMobilePreviewTwitterInfeedNextCardDivider",
        OPTIONS: "sharedMobilePreviewTwitterInfeedNextCardOptions",
        VIDEO: "sharedMobilePreviewTwitterInfeedNextCardVideo",
      },
      PROMOTED: "sharedMobilePreviewTwitterInfeedPromoted",
      PROMOTION_ICON: "sharedMobilePreviewTwitterInfeedPromotionIcon",
      RETWEETS: "sharedMobilePreviewTwitterInfeedRetweets",
      TAB: {
        EXPLORE: "sharedMobilePreviewTwitterInfeedTabExplore",
        HOME: "sharedMobilePreviewTwitterInfeedTabHome",
        MESSAGES: "sharedMobilePreviewTwitterInfeedTabMessages",
        NOTIFICATIONS: "sharedMobilePreviewTwitterInfeedTabNotifications",
      },
      VIEW_COUNT: "sharedMobilePreviewTwitterInfeedViewCount",
    },
  },
  YOUTUBE: {
    BUMPER: {
      AD_INFO_ICON: "sharedMobilePreviewYoutubeBumperAdInfoIcon",
      PROGRESS: "sharedMobilePreviewYoutubeTrueViewProgress",
      TIMER: "sharedMobilePreviewYoutubeBumperTimer",
    },
    SHARED: {
      AD_ICON: "sharedMobilePreviewYoutubeAdIcon",
      AUTOPLAY: "sharedMobilePreviewYoutubeAutoplay",
      BRAND_LOGO: "sharedMobilePreviewYoutubeBrandLogo",
      BRAND_NAME: "sharedMobilePreviewYoutubeBrandName",
      CTA: {
        BUTTON: "sharedMobilePreviewYoutubeCTAButton",
        CAPTION: "sharedMobilePreviewYoutubeCTACaption",
        MORE: "sharedMobilePreviewYoutubeCTAMore",
      },
      SUGGESTED_VIDEO_DESCRIPTION:
        "sharedMobilePreviewYoutubeSuggestedVideoDescription",
      SUGGESTED_VIDEOS: "sharedMobilePreviewYoutubeSuggestedVideos",
      UP_NEXT: "sharedMobilePreviewYoutubeUpNext",
      VIDEO_DESCRIPTION: "sharedMobilePreviewYoutubeVideoDescription",
      VIDEO_INTERACTIONS: "sharedMobilePreviewYoutubeVideoInteractions",
      VIDEO_OWNER: "sharedMobilePreviewYoutubeVideoOwner",
    },
    TRUEVIEW: {
      AD_INFO_ICON: "sharedMobilePreviewYoutubeTrueViewAdInfoIcon",
      COUNTDOWN: "sharedMobilePreviewYoutubeTrueViewCountdown",
      PROGRESS: "sharedMobilePreviewYoutubeTrueViewProgress",
      SKIP_AD_BUTTON: "sharedMobilePreviewYoutubeTrueViewSkipAdButton",
      TIMER: "sharedMobilePreviewYoutubeTrueViewTimer",
    },
    TRUEVIEW_FOR_ACTION: {
      INSTALL_BUTTON:
        "sharedMobilePreviewYoutubeTrueViewForActionInstallMoreButton",
      LEARN_MORE_BUTTON:
        "sharedMobilePreviewYoutubeTrueViewForActionLearnMoreButton",
    },
  },
};
