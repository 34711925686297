import COLORS from "shared/constants/colors";
import smallTextStyles from "shared/styling/SmallText";
import styled from "styled-components";

export const Toast = styled.div`
  ${smallTextStyles};
  background: ${COLORS.GREEN};
  border-radius: 1px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25);
  color: ${COLORS.DARK_GREY};
  display: inline-block;
  padding: 0.5rem;
  text-align: center;
  white-space: nowrap;

  &.isWarning {
    background: ${COLORS.RED};
    color: ${COLORS.WHITE};
  }
`;

export default Toast;
