import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["CLIENT_SIDE_ID"], "LD");

export const LAUNCH_DARKLY = {
  [Environment.Development]:
    process.env.LD_CLIENT_SIDE_ID || "62286fcae6b4e5152ae4abbe",
  [Environment.Live]: "62286ff5c9dfa214b100ea18",
  [Environment.Staging]:
    process.env.LD_CLIENT_SIDE_ID || "62286fda9d7333148d43d1d0",
};

export default LAUNCH_DARKLY;
