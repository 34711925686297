import LAYOUT from "shared/constants/layout";
import styled from "styled-components";

export const BOTTOM_PADDING = "4rem";

const PageContainer = styled.main`
  padding: 0 3rem ${BOTTOM_PADDING};

  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    padding: 0 1rem 1rem;
  }
`;

export default PageContainer;
