import Environment from "shared/types/environment";

// Segment set up for internal/showreel/integrations apps
export const ANALYTICS = {
  [Environment.Development]: "whXa5fL4iIRmd7K3Tn4Odv6m9hYTXVLc",
  [Environment.Live]: "5UfhevT3fs03cquUnyGRTACsllkekkb0",
  [Environment.Staging]: "cgX0dCHD1l7iwDFhwbdRMxUzydtZWp4e",
};

export default ANALYTICS;
