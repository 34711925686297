import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import styled, { css } from "styled-components";

export const avatarStyles = css`
  border-radius: 50%;
  height: 2.75rem;
  overflow: hidden;
  width: 2.75rem;
  > img {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

export const smallAvatarStyles = css`
  height: 1.75rem;
  width: 1.75rem;
`;

export const Avatar = styled.div`
  ${avatarStyles}
`;

export const SmallAvatar = styled.div`
  ${avatarStyles}
  ${smallAvatarStyles}
`;

export const OutlinedAvatar = styled.div`
  ${avatarStyles}
  ${smallAvatarStyles}
  border: 2px solid ${COLORS.BLUE};

  ${Icon} {
    bottom: 0.25rem;
    color: ${COLORS.BLUE};
    height: 0.75rem;
    position: absolute;
    right: -0.125rem;
    width: 0.75rem;
  }
`;

export default Avatar;
