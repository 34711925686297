import {
  ResponsiveGrid,
  ResponsiveGridColumnItem,
} from "shared/components/ResponsiveGrid/ResponsiveGrid";
import styled from "styled-components";

const HorizontalResponsiveGrid = styled(ResponsiveGrid)`
  flex-direction: row;
`;

export const FullHeight = styled(HorizontalResponsiveGrid)`
  height: auto;
  min-height: calc(100vh - 5.75rem); // Height of nav
`;

export const Column = styled(ResponsiveGridColumnItem)`
  align-items: flex-start;
  height: auto;
  padding: 0 10%;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export default {
  Column,
  FullHeight,
  Image,
};
