import { gql } from "@apollo/client";
import { UserFields, ViewerFields } from "shared/fragments/user";

export const GetViewerFields = gql`
  fragment GetViewerFields on User {
    ...UserFields
    ...ViewerFields
    auth0ID
    disableAnalytics
  }
  ${UserFields}
  ${ViewerFields}
`;

export const GET_VIEWER = gql`
  query Viewer {
    viewer {
      ...GetViewerFields
    }
  }
  ${GetViewerFields}
`;

export default {
  GET_VIEWER,
};
