import COLORS from "shared/constants/colors";
import styled from "styled-components";

export default styled.div<{ variation?: string }>`
  align-self: center;
  background-color: ${(props) =>
    props.variation === "light" ? COLORS.WHITE : COLORS.DARK_GREY};
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1.5rem 2rem 2rem;
  width: 37.5rem;

  h2 {
    margin-bottom: 2rem;
  }

  &:focus {
    outline: none;
  }
`;
