import { Auth0DecodedHash, WebAuth } from "auth0-js";
import axios from "axios";
import { IAuthConfig } from "shared/types/services/Auth";
import camelCaseKeys from "shared/utils/camelCaseKeys";

export default class Auth {
  private auth: WebAuth;
  private config: IAuthConfig;

  constructor(config: IAuthConfig) {
    this.config = config;
    const { audience, clientID, domain, redirectUri } = this.config;

    this.auth = new WebAuth({
      audience,
      clientID,
      domain,
      redirectUri,
      responseType: "token id_token",
      scope: "openid profile email",
    });
  }

  // SSO or other external authentication
  public authorize(connection?: string) {
    const { host, protocol } = window.location;
    const { redirectUri } = this.config;

    this.auth.authorize({
      audience: "https://vidsy.co",
      connection,
      domain: "login.vidsy.co",
      redirectUri,
      responseMode: "query",
      responseType: "code",
      scope: "openid email profile offline_access",
      state: btoa(
        `${protocol}//${host}/login/callback?app=internal#${this.randomString(32)}`, // This is used to prevent CSRF attacks on the BE and to help them identify the request in terms of credentials on the BE and make sure they are redirecting to the correct place
      ),
    });
  }

  public authorizeCallback(hash: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.parseHash({ hash }, function (err, authResult) {
        if (err) {
          return reject(err);
        }

        if (!authResult) {
          return reject();
        }

        resolve(authResult);
      });
    });
  }

  public authorizePopup(connection?: string) {
    // TODO: Remove when refresh token is enabled
    const { domain } = this.config;
    const { host, protocol } = window.location;

    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.popup.authorize(
        {
          connection,
          domain,
          redirectUri: `${protocol}//${host}/login/callback`,
          responseType: "token id_token",
          scope: "openid profile email",
        },
        (err, result) => {
          if (err?.name === "SyntaxError") {
            // JS issue parsing a response not from Auth0
            // https://github.com/auth0/auth0.js/issues/512
            return;
          }

          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public authorizePopupCallback(hash: string) {
    this.auth.popup.callback({ hash });
  }

  // Direct email/password authentication
  public authorizeWithCredentials(email: string, password: string) {
    const { realm } = this.config;

    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.client.login(
        {
          password,
          realm: realm || "",
          username: email,
          scope: "openid profile email",
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public logout(returnPath?: string) {
    let returnTo = returnPath;

    if (returnTo === undefined) {
      const { host, protocol } = window.location;

      returnTo = `${protocol}//${host}/login`;
    }

    const { clientID } = this.config;
    this.auth.logout({
      clientID,
      returnTo,
    });
  }

  // send email with code to user
  public passwordlessSend(email: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          authParams: {
            audience: "https://vidsy.co",
            scope: "openid profile email",
          },
          connection: "email",
          email,
          send: "code",
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  // send code to Auth0
  public passwordlessLogin(code: string, email: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.passwordlessLogin(
        {
          connection: "email",
          email: email,
          verificationCode: code,
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  public passwordlessCallback(hash: string) {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.parseHash({ hash }, function (err, authResult) {
        if (err) {
          return reject(err);
        }

        if (!authResult) {
          return reject();
        }

        resolve(authResult);
      });
    });
  }

  public reauthenticate() {
    const { host, protocol } = window.location;
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.auth.checkSession(
        {
          redirectUri: `${protocol}//${host}/login`,
          scope: "openid profile email",
        },
        (err, authResult) => {
          if (err) {
            return reject(err);
          }
          resolve(authResult);
        },
      );
    });
  }

  /* eslint-disable @typescript-eslint/naming-convention */ // For Auth0 API
  public async passwordlessOtpLogin(
    email: string,
    otp: string,
  ): Promise<Auth0DecodedHash> {
    const { audience, clientID, domain } = this.config;
    const url = `https://${domain}/oauth/token`;

    const body = {
      audience: audience,
      client_id: clientID,
      grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
      otp: otp,
      realm: "email",
      scope: "openid profile email offline_access",
      username: email,
    };

    try {
      const { data } = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const camelCasedData = camelCaseKeys(data);

      return camelCasedData as Auth0DecodedHash;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }

  public async refreshAccessToken(
    refreshToken: string,
  ): Promise<Auth0DecodedHash> {
    const url = `https://${this.config.domain}/oauth/token`;
    const body = {
      client_id: this.config.clientID,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: "openid profile email offline_access",
    };

    try {
      const { data } = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const camelCasedData = camelCaseKeys(data);

      return camelCasedData as Auth0DecodedHash;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }

  private randomString(length: number): string {
    const charset =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/";
    let result = "";
    let remainingLength = length;

    while (remainingLength > 0) {
      const bytes = new Uint8Array(16);
      const random = window.crypto.getRandomValues(bytes);

      random.forEach((index) => {
        if (remainingLength === 0) {
          return;
        }

        if (index < charset.length) {
          result += charset[index];
          remainingLength = remainingLength - 1;
        }
      });
    }

    return result;
  }
}
