import { forwardRef, ReactNode } from "react";
import ReactDom from "react-dom";
import styled from "styled-components";

const StyledModalWrapper = styled.div<{ variation?: string }>`
  background: ${(props) =>
    props.variation === "light"
      ? "rgba(0, 0, 0, 0.4)"
      : "rgba(255, 255, 255, 0.25)"};
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

interface IProps {
  children: ReactNode;
  className?: string;
  id?: string;
  variation?: string;
}

const ModalWrapper = forwardRef(
  (
    { children, className, id, variation }: IProps,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const node = (
      <StyledModalWrapper
        className={className}
        id={id}
        ref={ref}
        variation={variation}
      >
        {children}
      </StyledModalWrapper>
    );
    return ReactDom.createPortal(
      node,
      document.getElementById("modal-root") as Element,
    );
  },
);

export default ModalWrapper;
