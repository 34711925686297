import { lazy } from "react";

const CreativeX = lazy(() => import("./pages/CreativeX"));

export default [
  {
    authRequired: true,
    component: CreativeX,
    exact: true,
    path: "/brief/:briefShortHash",
  },
];
