import FONTS from "shared/constants/fonts";
import { css } from "styled-components";

export const h1Styles = css`
  font-size: 2rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  letter-spacing: -0.02rem;
  line-height: 1.25;
`;

export const h2Styles = css`
  font-size: 1.5rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 1.25;
`;

export const h3Styles = css`
  font-size: 1.125rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 1.25;
`;

export const h4Styles = css`
  font-size: 0.875rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 1.25;
`;

export default { h1Styles, h2Styles, h3Styles, h4Styles };
