import { useCallback, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

export const LOCAL_STORAGE_KEY = "useLandmarks";
const MAX_LANDMARKS = 4;
const VIDSY_TITLE_REGEX = / - Vidsy/gm;

export interface LandmarksProps {
  key: string;
  title: string;
  url: string;
}

const useLandmarks = (active?: boolean, key?: string) => {
  const history = useHistory();
  const url = history.location.pathname;
  const title = document.title.replace(VIDSY_TITLE_REGEX, "");

  const getLandmarks = useCallback(() => {
    try {
      const currentLandmarks =
        JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)!) || [];
      return currentLandmarks;
    } catch (e) {
      return e;
    }
  }, []);

  const landmarksSeen = getLandmarks();
  const filterList = landmarksSeen.filter(
    (item: LandmarksProps) => ![key, url].includes(item.key),
  );

  const updateLandmarks = useCallback(
    (overrideTitle?: string) => {
      try {
        const newLandmarks = [
          {
            key: key || url,
            title: overrideTitle || title,
            url,
          },
          ...filterList.slice(0, MAX_LANDMARKS),
        ];

        window.localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(newLandmarks),
        );

        return newLandmarks;
      } catch (e) {
        return e;
      }
    },
    [filterList, key, title, url],
  );

  // Handle case where page title has been changed in the time it took for this
  // hook to run for the first time and set up the MutationObserver
  // (usually happens on a forced page reload)
  useLayoutEffect(() => {
    if (!active) {
      return;
    }
    const latestTitle = document.querySelector("title")?.textContent;
    if (latestTitle && latestTitle !== title) {
      updateLandmarks(latestTitle.replace(VIDSY_TITLE_REGEX, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle case of any changes to the title after component tree renders
  useLayoutEffect(() => {
    if (!active) {
      return;
    }

    const observer = new MutationObserver(function (mutations) {
      const newTitle = mutations[0].target.textContent;
      if (newTitle) {
        updateLandmarks(newTitle.replace(VIDSY_TITLE_REGEX, ""));
      }
    });
    observer.observe(document.querySelector("title")!, {
      characterData: true,
      childList: true,
      subtree: true,
    });
    return () => observer.disconnect();
  }, [active, updateLandmarks]);

  if (active) {
    return updateLandmarks();
  }

  return landmarksSeen;
};

export default useLandmarks;
