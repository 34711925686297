import { Helmet } from "react-helmet";
import NotFoundContent from "shared/components/errors/NotFound";
import textualize from "shared/utils/textualize";

const NotFound = () => {
  return (
    <>
      <Helmet title={textualize("error.404.heading") as string} />
      <NotFoundContent />
    </>
  );
};

export default NotFound;
