import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useInterval from "react-useinterval";
import Button, { secondaryButtonStyles } from "shared/components/Button";
import Icon from "shared/components/Icon";
import { ModalContext } from "shared/components/ModalProvider";
import { ModalWrapper, StandardModal } from "shared/components/Modals";
import { ControlsRow } from "shared/components/Modals/styles";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import IDS from "shared/constants/ids";
import useModal from "shared/hooks/useModal";
import GoogleIcon from "shared/images/icons/google.svg";
import WarningIcon from "shared/images/icons/warning.svg";
import Auth from "shared/services/InternalAuth";
import SessionService from "shared/services/Session/Session";
import Application from "shared/types/application";
import getOrigin from "shared/utils/getOrigin";
import textualize from "shared/utils/textualize";
import styled from "styled-components";

interface IProps {
  Session: SessionService;
}

export const Banner = styled.section`
  align-items: center;
  background: ${COLORS.RED};
  color: ${COLORS.WHITE};
  display: flex;
  min-width: 45rem;
  padding: 1rem 3rem;
  position: sticky;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 3;
`;

export const StyledButton = styled(Button)`
  background: ${COLORS.WHITE};
  border-radius: 2rem;
  border: none;
  color: ${COLORS.DARK_GREY};
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 2.4rem;
  margin: auto 0 auto auto;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;

  & > span:last-child {
    position: relative;
    top: 0.125rem;
  }
`;

export const StyledModalButton = styled(Button)`
  border-radius: 2rem;
  margin: auto 0 auto auto;
  line-height: 2.4rem;
  padding: 0.25rem 1rem;
`;

export const StyledModalLink = styled.a`
  ${secondaryButtonStyles}
  border-radius: 2rem;
  line-height: 2rem;
  margin: auto auto auto 0;
  text-align: center;
  text-decoration: none;
`;

const StyledGoogleIcon = styled(Icon)`
  height: 2.5rem;
  vertical-align: middle;
  width: 2.5rem;
`;

const StyledWarningIcon = styled(Icon)`
  color: ${COLORS.WHITE};
  margin-right: 0.5rem;
`;

const StyledStandardModal = styled(StandardModal)`
  width: 40rem;
`;

export const formatTimeout = (ms: number) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = parseInt(((ms % 60000) / 1000).toFixed(0), 0);
  return seconds === 60
    ? `${minutes + 1}:00`
    : `${minutes}:${String(seconds).padStart(2, "0")}`;
};

function SessionDetails({ Session }: IProps) {
  const [expiresIn, setExpiresIn] = useState<number | false>(false);

  const checkExpiry = () => {
    setExpiresIn(Session.willExpireInMinutes(15)); // Returns ms to time expired
  };

  const countdown = () => {
    if (expiresIn && expiresIn > 0) {
      setExpiresIn(expiresIn - 1000);
    }
  };

  useEffect(() => {
    // Check for logout on window refocus in case the user
    // has cleared cookies in a different tab
    function focusCheck() {
      if (!Session.checkLocalAuthentication()) {
        // Log out
        Auth.logout(`${getOrigin(Application.Internal)}/login`);
        Session.clear();
      }
    }
    window.addEventListener("focus", focusCheck);
    return () => window.removeEventListener("focus", focusCheck);
  }, [Session]);

  useInterval(checkExpiry, 1000 * 60); // Check every minute
  useEffect(checkExpiry, [Session]); // And also once on first render
  useInterval(countdown, 1000);

  const { modals } = useContext(ModalContext);
  const { closeAllModals, closeModal, openModal } = useModal(
    IDS.MODALS.SESSION_EXPIRED.ID,
  );

  if (expiresIn === false) {
    // If we're showing the session expired modal, hide it
    if (modals.length && modals[0].id === IDS.MODALS.SESSION_EXPIRED.ID) {
      closeModal();
    }
    return null;
  }

  async function reauthenticate(event: React.MouseEvent) {
    event.preventDefault();

    try {
      // Silent re-auth
      const result = await Auth.reauthenticate();
      Session.updateSession(result);
      setExpiresIn(false);
    } catch {
      try {
        // Regular login flow
        const result = await Auth.authorizePopup("google-oauth2");
        Session.updateSession(result);
        setExpiresIn(false);
      } catch {
        // Add flag for redirected login form to pick up
        sessionStorage.setItem("reauth_failed", "true");

        // Log out
        Auth.logout(`${getOrigin(Application.Internal)}/login`);
        Session.clear();
      }
    }
  }

  let message;
  if (expiresIn > 0) {
    const timeout = <strong>{formatTimeout(expiresIn)}</strong>;
    message = textualize("general.session.expiryWarning", {
      timeout,
    });
  } else {
    const component = (
      <ModalWrapper id={IDS.MODALS.SESSION_EXPIRED.ID}>
        <Helmet title={textualize("general.session.expiredTitle") as string} />
        <StyledStandardModal>
          <h2>{textualize("general.session.expired")}</h2>
          <ControlsRow>
            <StyledModalLink
              href={`${getOrigin(Application.Internal)}/login`}
              id={IDS.MODALS.SESSION_EXPIRED.LOGIN_LINK}
              onClick={closeAllModals}
            >
              {textualize("general.session.returnToSignin")}
            </StyledModalLink>
            <StyledModalButton
              id={IDS.MODALS.SESSION_EXPIRED.REAUTH_BUTTON}
              onClick={reauthenticate}
            >
              <StyledGoogleIcon component={GoogleIcon} />{" "}
              {textualize("general.signInWithGoogle.button")}
            </StyledModalButton>
          </ControlsRow>
        </StyledStandardModal>
      </ModalWrapper>
    );

    openModal(component, true);

    return null; // Don't show the banner
  }

  return (
    <Banner id={IDS.SESSION_DETAILS.BANNER}>
      <Helmet title={textualize("general.session.expiringTitle") as string} />
      <StyledWarningIcon component={WarningIcon} />
      <span>{message}</span>
      <StyledButton onClick={reauthenticate}>
        <StyledGoogleIcon component={GoogleIcon} />
        <span>{textualize("general.session.reauthenticate")}</span>
      </StyledButton>
    </Banner>
  );
}

export default SessionDetails;
