import { ApolloLink } from "@apollo/client";
import { GraphQLError } from "graphql";

// Add x-datadog-trace-id value to any GQL errors returned if present
export const traceLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const { response } = operation.getContext();
    const { headers } = response;

    if (data.errors) {
      const traceID: string = headers.get("x-datadog-trace-id");
      if (traceID) {
        data.errors = data.errors.map((err) => {
          const { message, extensions, ...rest } = err;
          return new GraphQLError(message, {
            ...rest,
            extensions: {
              ...extensions,
              traceID,
            },
          });
        });
      }
    }

    return data;
  });
});
