import { useState } from "react";
import { NavAnchor, NavLink } from "shared/components/GlobalNav/styles";
import Icon from "shared/components/Icon";
import RecentlyVisitedTile from "shared/components/RecentlyVisitedTile";
import SlackTile from "shared/components/SlackTile";
import COLORS from "shared/constants/colors";
import LAYOUT from "shared/constants/layout";
import useLandmarks from "shared/hooks/useLandmarks";
import CloseIcon from "shared/images/icons/close.svg";
import MenuIcon from "shared/images/icons/hamburger.svg";
import styled from "styled-components";

interface IOpenProps {
  open: boolean;
}

const StyledIcon = styled(Icon)`
  color: ${COLORS.WHITE};
  height: 1.5rem;
  width: 1.5rem;
`;

export const ToggleButton = styled.button<IOpenProps>`
  display: none;
  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    appearance: none;
    background-color: ${(props) =>
      props.open ? COLORS.LIGHT_GREY : "transparent"};
    border: none;
    border-bottom: 2.5rem solid
      ${(props) => (props.open ? COLORS.LIGHT_GREY : "transparent")};
    border-radius: 0.25rem 0.25rem 0 0;
    bottom: 100%;
    color: ${COLORS.WHITE};
    cursor: pointer;
    display: block;
    height: 3rem;
    padding: 0.75rem 0.5rem;
    position: absolute;
    width: 3rem;
  }
`;

const NavItemsContainer = styled.div`
  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    padding: 0.5rem 1rem;
  }
`;

export const NavItems = styled.nav<IOpenProps>`
  grid-area: nav;
  @media (max-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    background-color: ${COLORS.LIGHT_GREY};
    margin-top: 1rem;
    position: relative;

    ${NavAnchor},
    ${NavLink} {
      display: block;
      line-height: 1.25rem;
      margin: 0;
      padding: 0.5rem 0;
    }

    ${NavItemsContainer} {
      display: ${(props) => (props.open ? "block" : "none")};
    }
  }
`;

export const NavLeftCol = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: inline;
  }
`;

const NavRightCol = styled.div`
  padding-top: 1.75rem;

  @media (min-width: ${LAYOUT.RESPONSIVE_GRID_BREAKPOINT_SIZE}) {
    display: none;
  }
`;

const NavItemsWrapper = styled.div`
  display: grid;
  gap: 0 1px;
  grid-template-columns: 30% 70%;
`;

const StyledRecentlyVisitedTile = styled(RecentlyVisitedTile)`
  li {
    display: block;
  }
`;

interface IProps {
  children: React.ReactNode;
}

function GlobalNavItems({ children }: IProps) {
  const [open, setOpen] = useState(false);
  const getLandmarks = useLandmarks(false);
  return (
    <NavItems open={open}>
      <ToggleButton onClick={() => setOpen((isOpen) => !isOpen)} open={open}>
        <StyledIcon component={open ? CloseIcon : MenuIcon} />
      </ToggleButton>
      <NavItemsContainer>
        <NavItemsWrapper>
          <NavLeftCol>{children}</NavLeftCol>

          <NavRightCol>
            <SlackTile />
            {!!getLandmarks.length && (
              <StyledRecentlyVisitedTile items={getLandmarks} />
            )}
          </NavRightCol>
        </NavItemsWrapper>
      </NavItemsContainer>
    </NavItems>
  );
}

export default GlobalNavItems;
