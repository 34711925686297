import API from "shared/constants/api";
import { default as DATADOG_DEFAULTS } from "shared/constants/datadog";
import getEnvironment from "shared/utils/getEnvironment";

export const CONFIG = {
  ...DATADOG_DEFAULTS.CONFIG,
  ALLOWED_TRACING_URLS: [
    {
      match: API.INTEGRATIONS[getEnvironment()].replace("/query", ""),
      propagatorTypes: ["datadog" as const],
    },
  ],
  APPLICATION_ID: "45390d9c-1dbb-4026-b337-6cf16f3224b8",
  CLIENT_TOKEN: "pub943275013b3ebdb23fb6f8a355fad678",
  SERVICE: "integrations.vidsy.co",
};

export const IGNORE_ERRORS = [...DATADOG_DEFAULTS.IGNORE_ERRORS];

export default {
  CONFIG,
  IGNORE_ERRORS,
};
