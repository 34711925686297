import { ReactNode } from "react";
import PageContainer from "shared/components/PageContainer";
import PageHeading from "shared/components/PageHeading";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import textualize from "shared/utils/textualize";
import { Column, FullHeight } from "shared/components/errors/ErrorComponents";
import styled from "styled-components";
import notFoundAnimation from "./notFoundAnimation";

export interface IProps {
  links?: ReactNode;
}

export const HelpfulLinks = styled.div`
  margin-top: 2rem;
`;

const NotFoundContent = ({ links }: IProps) => {
  const { AnimationComponent: NotFoundAnimation } = useLottieAnimation({
    animation: notFoundAnimation,
  });
  return (
    <PageContainer>
      <FullHeight>
        <Column>
          <PageHeading>{textualize("error.404.heading")}</PageHeading>
          <p>{textualize("error.404.body")}</p>
          {links && (
            <HelpfulLinks>
              <p>{textualize("error.404.description")}</p>
              {links}
            </HelpfulLinks>
          )}
        </Column>
        <Column>{NotFoundAnimation}</Column>
      </FullHeight>
    </PageContainer>
  );
};

export default NotFoundContent;
