import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import { css } from "styled-components";

export const linkStyles = css`
  color: ${COLORS.WHITE};
  font: 0.875rem ${FONTS.FAMILY.BODY};
  letter-spacing: 0.05rem;
  text-decoration: none;
  text-transform: uppercase;
  &:focus,
  &:hover {
    color: ${COLORS.RED};
  }
`;

export default linkStyles;
