import { ApolloCache, ApolloClient, ApolloLink } from "@apollo/client";

interface IClientConfig<TCacheShape = object> {
  cache: ApolloCache<TCacheShape>;
  devtools?: {
    name: string;
    enabled?: boolean;
  };
  link: ApolloLink;
  resolvers?: any;
  typeDefs?: any;
}

function getClient({
  cache,
  devtools,
  link,
  resolvers,
  typeDefs,
}: IClientConfig) {
  return new ApolloClient({
    cache,
    devtools,
    link,
    resolvers,
    typeDefs,
  });
}

export default getClient;
