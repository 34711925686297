import { Component, createRef, KeyboardEvent, MouseEvent } from "react";
import {
  Container,
  InvisibleButton,
  PopoverContent,
} from "./PopoverComponents";

export interface IProps {
  className?: string;
  children?: any;
  id?: string;
  label?: string;
  trigger: any;
}

export interface IState {
  focused?: number;
  isOpen: boolean;
}

export default class Popover extends Component<IProps, IState> {
  public state: IState = {
    isOpen: false,
  };

  private buttonRef = createRef<HTMLButtonElement>();

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        // Add close listeners
        document.addEventListener("click", this.toggleOpen);
      } else {
        // Remove close listeners
        document.removeEventListener("click", this.toggleOpen);
      }
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.toggleOpen);
  }

  public render() {
    const { isOpen } = this.state;
    const { className, children, id, label, trigger } = this.props;

    return (
      <Container className={className}>
        <InvisibleButton
          aria-expanded={isOpen ? true : undefined}
          aria-haspopup={true}
          aria-label={label}
          id={id}
          onClick={this.onButtonClick}
          onKeyDown={this.onButtonKeyDown}
          ref={this.buttonRef as React.MutableRefObject<HTMLButtonElement>}
        >
          {trigger}
        </InvisibleButton>
        {isOpen && (
          <PopoverContent onKeyDown={this.onMenuKeyDown} role="menu">
            {children}
          </PopoverContent>
        )}
      </Container>
    );
  }

  private closeMenu = (focusButton = true) => {
    this.toggleOpen();

    if (focusButton && this.buttonRef?.current) {
      this.buttonRef.current.focus();
    }
  };

  private focusChildAt(position: number) {
    // Handle single children like multiple children
    let children = this.props.children;

    if (!Array.isArray(children)) {
      children = [children];
    }

    // Remove any false-y children (e.g. conditional options)
    children = children.filter((child: any) => !!child);

    if (children[position] && children[position].ref) {
      children[position].ref.current.focus();

      this.setState({ focused: position });
    }
  }

  private onButtonKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (this.state.isOpen) {
        // Apply focus to first child
        this.focusChildAt(0);
      } else {
        this.toggleOpen();
      }
    }
  };

  private onMenuKeyDown = (e: KeyboardEvent) => {
    // Handle single children like multiple children
    let children = this.props.children;

    if (!Array.isArray(children)) {
      children = [children];
    }

    e.stopPropagation();

    let nextFocused;

    switch (e.key) {
      case "Tab":
        this.closeMenu(false);
        break;
      case "Escape":
        e.preventDefault();
        this.closeMenu();
        break;
      case "ArrowUp":
        e.preventDefault();

        if (this.state.focused === 0) {
          // Loop back to bottom
          nextFocused = children.length - 1;
        } else {
          // Next one up
          nextFocused = this.state.focused! - 1;
        }

        this.focusChildAt(nextFocused);
        break;
      case "ArrowDown":
        e.preventDefault();

        if (this.state.focused === children.length - 1) {
          // Loop back to top
          nextFocused = 0;
        } else {
          // Next one down
          nextFocused = this.state.focused! + 1;
        }

        this.focusChildAt(nextFocused);
        break;
    }
  };

  private toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private onButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    this.toggleOpen();
  };
}
