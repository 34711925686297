import COLORS from "shared/constants/colors";
import styled from "styled-components";

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 37.5rem;

  h2 {
    margin-bottom: 2rem;
  }

  &:focus {
    outline: none;
  }
`;

export const Top = styled.div`
  background-color: ${COLORS.DARK_GREY};
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  padding: 1.5rem 2rem 2.5rem;
`;

export const Bottom = styled.div`
  background-color: ${COLORS.MID_GREY};
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  padding: 2.5rem 2rem 2rem;
  position: relative;
`;

export default Container;
