import { minorButtonStyles } from "shared/components/Button";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import styled from "styled-components";

export const MinorButton = styled.button<{ variation?: string }>`
  ${minorButtonStyles}
  ${({ variation }) =>
    variation === "light" &&
    `
      border-color: ${COLORS.DARK_GREY};
      border-width: 0.063rem;
      color: ${COLORS.DARK_GREY};
      font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
    `};
`;

export const CancelButton = styled(MinorButton)`
  color: ${COLORS.WHITE};
`;

export const ControlsRow = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const ModalInput = styled.input`
  appearance: none;
  border: none;
  display: block;
  font: 1rem ${FONTS.FAMILY.BODY};
  margin: 1rem 0;
  padding: 0.5rem 0;
  width: 100%;
`;

export const Error = styled.div`
  color: ${COLORS.WHITE};
`;

export const ErrorableInput = styled.div<any>`
  background: ${(props) => (props.hasError ? `${COLORS.RED}` : "transparent")};
  margin-bottom: 1rem;
  border-radius: 1px;
  padding: 0.125rem;

  > input {
    margin-bottom: ${(props) => (props.hasError ? "0" : "0.5rem")};
  }

  ${Error} {
    font-size: 0.75rem;
    padding: 0.375rem 0.5rem 0.25rem;
  }
`;
