import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import {
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
} from "shared/styling/Heading/Heading";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
  /* CSS Reset */
  ${reset}

  html {
    background-color: ${COLORS.DARK_GREY};
    /* Box model */
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    font: 16px ${FONTS.FAMILY.BODY}; /* rem sizing for borders, padding etc. */
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background-color: ${COLORS.DARK_GREY};
    /* Fonts */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.875rem; /* Body text at 14px default */
    line-height: 1.5;
  }

  h1 {
    ${h1Styles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
  }

  h4 {
    ${h4Styles}
  }

  a {
    color: ${COLORS.RED};

    &:focus, &:hover {
      color: ${COLORS.WHITE};
    }
  }

  code {
    background: ${COLORS.DARK_GREY};
    color: ${COLORS.BLUE};
    font-family: ${FONTS.FAMILY.MONOSPACE};
    padding: 0 0.25rem 0.25rem 0.25rem;
  }

  strong {
    font-weight: ${FONTS.WEIGHT.BOLD};
  }

  em {
    font-style: italic;
  }
`;
